import './Filter.scss';

const Filter = ({ options, selectedOption, setFilter, title }) => {
  return (
    <div className="filter">
      <div className="filter-title">{title}</div>
      {options.map(option => {
        const isSelected = option === selectedOption;
        return (
          <label key={option} className="filter-option">
            <input type="checkbox" checked={isSelected} onChange={() => setFilter(isSelected ? '' : option)} />
            {option}
          </label>
        );
      })}
    </div>
  );
}

export default Filter;
