import React, { useEffect, useState } from 'react';

import RecipeList from './components/RecipeList/RecipeList';
import RecipeItem from './components/RecipeItem/RecipeItem';
import { fetchAllRecipes, fetchFilters } from './utils/api';

import './App.scss';

const SAVED_RECIPES_KEY = 'saved_recipes';
const storeSavedRecipes = (savedRecipes) => {
  localStorage.setItem(SAVED_RECIPES_KEY, JSON.stringify(savedRecipes));
  localStorage.setItem(`${SAVED_RECIPES_KEY}_date`, new Date().toString())
}
const getSavedRecipesFromLocalStorage = () => JSON.parse(localStorage.getItem(SAVED_RECIPES_KEY));

const setUrlQueryParams = (pathWithQuery = '/') => window.history.replaceState(null, '', pathWithQuery);
const setSavedRecipesQueryParam = (savedRecipes) => {
  let idString = savedRecipes.join(',');
  if (idString.length > 0) {
    setUrlQueryParams(`/?${SAVED_RECIPES_KEY}=${idString}`);
  } else {
    setUrlQueryParams('/');
  }
}
const getQueryParamsObjFromUrl = () => {
  const queryString = window.location.search;
  const queryParamString = queryString.split('?')[1]; // remove leading '?'
  if (!queryParamString) return {};
  const queryParamsObj = {};
  queryParamString.split('&').forEach(paramString => {
    const keyValueArray = paramString.split('=');
    const key = keyValueArray[0];
    const value = keyValueArray[1];
    queryParamsObj[key] = value;
  });
  return queryParamsObj;
}
const getSavedRecipeIdsFromUrl = () => {
  const queryParams = getQueryParamsObjFromUrl();
  const savedRecipesString = queryParams[SAVED_RECIPES_KEY];
  if (!savedRecipesString) return [];
  return savedRecipesString.split(',').map(id => parseInt(id, 10));
}

function App() {
  const [allRecipes, setAllRecipes] = useState([]);
  const [allFilters, setAllFilters] = useState({});
  const [savedRecipes, setSavedRecipes] = useState([]);
  const hasSavedRecipes = savedRecipes.length > 0;

  useEffect(() => {
    const init = async () => {
      // fetch all recipes
      const recipesResponse = await fetchAllRecipes();
      setAllRecipes(recipesResponse);

      // fetch all filters
      const filtersResponse = await fetchFilters();
      setAllFilters(filtersResponse);

      // read saved recipes from url, if available
      const savedRecipesFromUrl = getSavedRecipeIdsFromUrl();
      if (savedRecipesFromUrl.length > 0) {
        setSavedRecipes(savedRecipesFromUrl);
      } else { // else, read saved recipes from local storage, if available
        const storedSavedRecipes = getSavedRecipesFromLocalStorage();
        if (storedSavedRecipes) setSavedRecipes(storedSavedRecipes);
      }
    };
    init();
  }, []);

  const [savedRecipesOpen, setSavedRecipesOpen] = useState(false);
  const toggleSavedRecipes = () => setSavedRecipesOpen(prev => !prev);

  useEffect(() => {
    if (savedRecipes.length === 0 && savedRecipesOpen) {
      toggleSavedRecipes();
    }
  }, [savedRecipes, savedRecipesOpen, toggleSavedRecipes]);

  const updateSavedRecipes = (newSavedRecipes) => {
    storeSavedRecipes(newSavedRecipes);
    setSavedRecipes(newSavedRecipes);
    setSavedRecipesQueryParam(newSavedRecipes);
  };

  const saveRecipe = (id) => {
    if (savedRecipes.find(savedId => savedId === id)) return;
    const newSavedRecipes = [...savedRecipes, id];
    updateSavedRecipes(newSavedRecipes);
  };

  const removeRecipe = (id) => {
    const newSavedRecipes = savedRecipes.filter(savedId => savedId !== id);
    updateSavedRecipes(newSavedRecipes);
  };

  const removeAllRecipes = () => {
    updateSavedRecipes([]);
  }

  const toggleSavedRecipesLabel = savedRecipesOpen ? 'Close' : `Meals [${savedRecipes.length}]`;

  return (
    <div className="app">
      <header className="app-header">
        <h1 className="app-title">Meal Planner</h1>
        {hasSavedRecipes ? (
          <>
            <button className="saved-recipes-toggle button primary" onClick={toggleSavedRecipes}>{toggleSavedRecipesLabel}</button>
            <div className={`saved-recipes ${savedRecipesOpen ? 'open' : ''}`}>
              <div className="saved-recipes-title">Saved Meals</div>
              {savedRecipes.map(id => {
                const recipe = allRecipes.find(recipe => recipe.id === id);
                return <RecipeItem key={id} recipe={recipe} handleRemove={removeRecipe} isSaved />;
              })}
              <button className="clear-saved-recipes button" onClick={removeAllRecipes}>Clear List</button>
            </div>
          </>
        ) : null}
      </header>
      <div className="app-content">
        <RecipeList
          allFilters={allFilters}
          allRecipes={allRecipes}
          removeRecipe={removeRecipe}
          saveRecipe={saveRecipe}
          savedRecipes={savedRecipes}
        />
      </div>
    </div>
  );
}

export default App;
