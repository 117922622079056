const S3_URL = 'https://meal-planner-data.s3.us-west-2.amazonaws.com';
const S3_PATH_RECIPES = '/data/recipes.json';
const S3_PATH_FILTERS = '/data/filters.json';

export const fetchAllRecipes = async () => {
  const response = await fetch(`${S3_URL}${S3_PATH_RECIPES}`);
  const data = await response.json();
  return data.recipes.map((recipe, key) => ({ ...recipe, id: key }));
};

export const fetchFilters = async () => {
  const response = await fetch(`${S3_URL}${S3_PATH_FILTERS}`);
  return response.json();
};
