import React, { useEffect, useState } from 'react';

import './RecipeList.scss';
import Filter from '../Filter/Filter';
import RecipeItem from '../RecipeItem/RecipeItem';

const DEFAULT_SELECTED_FILTERS = {};

const RecipeList = ({
  allFilters,
  allRecipes,
  removeRecipe,
  saveRecipe,
  savedRecipes,
}) => {
  const [filterCategories, setFilterCategories] = useState([]);

  useEffect(() => {
    setFilterCategories(Object.keys(allFilters));
  }, [allFilters]);
  
  const [filteredRecipes, setFilteredRecipes] = useState(allRecipes);
  const [selectedFilters, setSelectedFilters] = useState(DEFAULT_SELECTED_FILTERS);

  useEffect(() => {
    const newRecipes = allRecipes.filter(({ tags }) => {
      let includeRecipe = true;
      filterCategories.forEach(filterType => {
        const selectedFilter = selectedFilters[filterType];
        if (selectedFilter && !tags.includes(selectedFilter)) includeRecipe = false;
      });
      return includeRecipe;
    });
    setFilteredRecipes(newRecipes);
  }, [allRecipes, selectedFilters]);

  const setFilter = (filterType, selectedOption) => {
    setSelectedFilters(prev => ({ ...prev, [filterType]: selectedOption }));
  }

  const clearAllFilters = () => {
    setSelectedFilters(DEFAULT_SELECTED_FILTERS);
  };

  return (
    <div className="recipe-list-container">
      {filterCategories.length ? (
        <>
          <div className="filters-container">
            {filterCategories.map(filterType => (
              <Filter
                key={filterType}
                title={filterType}
                options={allFilters[filterType]}
                selectedOption={selectedFilters[filterType]}
                setFilter={(selectedOption) => setFilter(filterType, selectedOption)}
              />
            ))}
          </div>
        <button className="clear-filters-button" onClick={clearAllFilters}>Clear All Filters</button>
        </>
      ) : null}
      <div className="grid">
        {filteredRecipes.map(recipe => (
          <RecipeItem
            key={recipe.id}
            handleRemove={removeRecipe}
            handleSave={saveRecipe}
            isSaved={savedRecipes.includes(recipe.id)}
            recipe={recipe}
          />
        ))}
        <div className="empty-message">
          {allRecipes.length === 0 ? (
            'Loading...'
          ) : filteredRecipes.length === 0 ? (
            'No recipes match your filter settings. Change your filters to see more recipes.'
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default RecipeList;
