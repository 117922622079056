import './RecipeItem.scss';

const RecipeItem = ({ recipe, handleRemove, handleSave, isSaved }) => {
  const { id, name, image, link } = recipe;
  return (
    <div className={`recipe-list-item ${isSaved ? 'saved' : ''}`}>
      <img className="image" src={image} alt="" />
      <div className="details">
        <div className="title">{name}</div>
        <div className="action-container">
          <div className="action">
            <a className="button" href={link} rel="noopener noreferrer" target="_blank">Details</a>
          </div>
          <div className="action">
            {isSaved ? (
              <button className="button primary" onClick={() => handleRemove(id)}>Remove</button>
            ) : (
              <button className="button primary" onClick={() => handleSave(id)}>Save</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecipeItem;
